const $ = require('jquery');
const WOW = require('wow.js');

const menuIcon = document.getElementById('menu-icon');
const navButton = document.getElementsByClassName('nav-button');
var map;
let slides = $('.slides').children();
let numbers = $('.index').children();
let mapConfig = {
  zoom: 15,
  styles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161'
        }
      ]
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e'
        }
      ]
    }
  ]
};

window.$ = $;

window.initMap = function() {
  map = new google.maps.Map(
    document.getElementById('map'),
    Object.assign(mapConfig, {
      center: { lat: 19.190564, lng: -96.126956 }
    })
  );
  var marker = new google.maps.Marker({
    position: { lat: 19.190564, lng: -96.126956 },
    title: 'Uninaciones',
    map: map,
    icon: '../media/courses/global/marker.png'
  });
};

window.initContactMap = function() {
  map = new google.maps.Map(
    document.getElementById('map'),
    Object.assign(mapConfig, {
      center: { lat: 19.190564, lng: -96.115956 }
    })
  );
  var marker = new google.maps.Marker({
    position: { lat: 19.190564, lng: -96.126956 },
    title: 'Uninaciones',
    map: map,
    icon: '../media/courses/global/marker.png'
  });
};

function slider() {
  let i = 0;
  slides.each(function() {
    $(this).hide();
  });
  $(slides[0]).show();
  $('#previous').hide();

  $('#next').click(function() {
    nextSlide(i);
    i++;
    if (i >= slides.length - 1) {
      $('#next').hide();
    }
    if ($('#previous').is(':hidden')) {
      $('#previous').show();
    }
  });
  $('#previous').click(function() {
    previousSlide(i);
    i--;
    if ($('#next').is(':hidden')) {
      $('#next').show();
    }
    if (i == 0) {
      $('#previous').hide();
    }
  });
}

function nextSlide(currentPage) {
  $(numbers[currentPage]).removeClass('active');
  $(numbers[currentPage + 1]).addClass('active');
  $(slides[currentPage]).addClass('animated slideOutLeft');
  $(slides[currentPage + 1])
    .addClass('animated slideInRight transition')
    .show();
  setTimeout(function() {
    $(slides[currentPage]).hide();
    $(slides[currentPage + 1]).removeClass('transition');
    resetClasses();
  }, 1000);
}

function previousSlide(currentPage) {
  $(numbers[currentPage]).removeClass('active');
  $(numbers[currentPage - 1]).addClass('active');
  $(slides[currentPage]).addClass('animated slideOutRight');
  $(slides[currentPage - 1])
    .addClass('animated slideInLeft transition')
    .show();
  setTimeout(function() {
    $(slides[currentPage]).hide();
    $(slides[currentPage - 1]).removeClass('transition');
    resetClasses();
  }, 1000);
}

function resetClasses() {
  slides.each(function() {
    $(this).removeClass(
      'animated slideOutLeft slideInRight slideInLeft slideOutRight'
    );
  });
}

function toggleMenu() {
  $('#menu').slideToggle(500);
  if ($('#menu-icon').attr('data-toggle') == 'true') {
    $('body').css('overflow', 'hidden');
    $('#menu-icon').attr('data-toggle', 'false');
  } else if ($('#menu-icon').attr('data-toggle') == 'false') {
    $('#nav').css('background-color', 'transparent');
    $('#logo').animate({ 'margin-left': 0 });
    $('body').css('overflow', 'scroll');
    $('#menu-icon').attr('data-toggle', 'true');
  }
}

function carousel(selector, interval) {
  let slides = $(selector).children();
  slides.each(function() {
    $(this).hide();
  });
  $(slides[0]).fadeIn(1000);
  let i = 0;
  let j = 1;
  setInterval(function() {
    $(slides[i]).hide();
    $(slides[j]).fadeIn(1000);
    i++;
    j++;
    if (i === slides.length) i = 0;
    if (j === slides.length) j = 0;
  }, interval);
}

function showProfileModal() {
  $('.modal.profile').addClass('active');
  $('body').addClass('modal-wrapper');
}

function hideProfileModal() {
  $('.modal.profile').removeClass('active');
  $('.modal.profile').addClass('animated');
  $('.modal.profile .content').addClass('animated');
  $('body').removeClass('modal-wrapper');
}

function showFieldModal() {
  $('.modal.field').addClass('active');
  $('body').addClass('modal-wrapper');
}

function hideFieldModal() {
  $('.modal.field').removeClass('active');
  $('.modal.field').addClass('animated');
  $('.modal.field .content').addClass('animated');
  $('body').removeClass('modal-wrapper');
}

window.showProfileModal = showProfileModal;
window.hideProfileModal = hideProfileModal;

window.showFieldModal = showFieldModal;
window.hideFieldModal = hideFieldModal;

$(document).ready(function() {
  carousel('.heroes', 7000);
  carousel('.testimonies', 7000);
  new WOW().init();
  slider();
});

menuIcon.addEventListener('click', toggleMenu);
